import { rem } from '@butcherbox/freezer'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import Ada from '~/routes/Legal/Ada/Ada'
import { cleanJsonStrings } from '~/utils/unicode'

export default function () {
  const { pageData } = cleanJsonStrings(
    useStaticQuery<{ pageData: any }>(graphql`
      query {
        pageData: contentfulProtectedPage(pageId: { eq: "ada" }) {
          ...ProtectedPage
        }
      }
    `)
  )

  const { header } = pageData
  return (
    <UnauthenticatedLayout
      className="legalPage"
      cta={header?.cta?.content}
      margin="auto"
      maxW={{ base: '100%', desktop: '120ch' }}
      px={rem(46)}
      py={rem(36)}
      title="Legal Page"
    >
      <Ada />
    </UnauthenticatedLayout>
  )
}
